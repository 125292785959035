import React, { FC, useState } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { colorPalette } from '@shared/theme';

export interface IChartValues {
	first: {
		title: string;
		value: string;
	};
	second: {
		title: string;
		value: string;
	};
}

export interface IChartProps {
	grades: number[];
	values: IChartValues;
	isMobile?: boolean;
}

interface IStyledProps {
	gradeHeight?: number;
	barColor?: string;
	minimized?: boolean;
}

const ChartWrapperStyled = styled.div`
	display: flex;
	max-height: 360px;

	@media screen and (max-width: 1265px) {
		max-height: 300px;
	}

	@media screen and (max-width: 1135px) {
		max-height: 220px;
	}

	@media screen and (max-width: 992px) {
		height: 300px;
		max-height: 300px;
		margin-bottom: 40px;
	}
`;

const ChartGradesStyled = styled.div`
	display: flex;
	margin-right: 28px;
`;

const ChartGradeItemStyled = styled.div`
	display: flex;
	align-items: flex-center;

	${(props: IStyledProps) => `height: ${props.gradeHeight}%`};
`;

const ChartGradeNumberStyled = styled.p`
	padding: 0 20px 0 0;
	font-size: 15px;
	font-weight: 500;
	color: ${colorPalette.dimGray};
`;

const ChartGradeLineStyled = styled.div`
	height: 15px;
	width: 7px;
	background-color: rgba(0, 121, 193, 0.5);

	&:nth-of-type(even) {
		opacity: 0.5;
	}
`;

const ChartGradesWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

const ChartLinesWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

const ChartBarWrapperStyled = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;

	@media screen and (max-width: 992px) {
		margin-bottom: 30px;
	}
`;

const ChartBarStyled = styled.div`
	position: relative;
	display: flex;
	width: 140px;
	justify-content: center;

	cursor: pointer;
	transition: height 0.33s linear;

	background-color: ${(props: IStyledProps) => props.barColor};
	${(props: IStyledProps) => `height: ${props.gradeHeight}%`};

	&:first-child {
		margin-right: 40px;
	}

	@media screen and (max-width: 1265px) {
		width: 120px;

		&:first-child {
			margin-right: 20px;
		}
	}

	@media screen and (max-width: 1135px) {
		width: 100px;

		&:first-child {
			margin-right: 10px;
		}
	}

	@media screen and (max-width: 992px) {
		width: 154px;

		&:first-child {
			margin-right: 3px;
		}
	}
`;

const ChartBarTitleStyled = styled.p`
	position: absolute;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.2;
	top: calc(100% + 30px);
	color: ${(props: IStyledProps) => props.barColor};

	@media screen and (max-width: 1265px) {
		top: calc(100% + 10px);
	}

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const ChartBarNumberStyled = styled.p`
	padding: 15px 0 0;
	font-weight: 500;
	font-size: 15px;
	color: ${colorPalette.white};
	display: ${(props: IStyledProps) => (props.minimized ? 'none' : 'auto')};
`;

const LabelMobileStyled = styled.p`
	@media screen and (min-width: 992px) {
		display: none;
	}

	position: absolute;
	top: calc(100% + 24px);
	color: ${(props: IStyledProps) => props.barColor};
	max-width: 75px;
	padding: 0;
	font-size: 19px;
	font-weight: 500;
	line-height: 1.47;
	margin: 0;
`;

export const Chart: FC<IChartProps> = ({
	values,
	grades,
	isMobile = false,
}) => {
	const [minimized, setMinimized] = useState<boolean>(true);

	function handleChartClick(): void {
		setMinimized(!minimized);
	}

	function onVisibilityChange(isVisible: boolean) {
		if (isVisible) setMinimized(false);
	}

	const maxGrade = Number(grades[0]);
	const firstValue =
		(Number(values.first.value.split('-').pop()) / maxGrade) * 100;
	const secondValue =
		(Number(values.second.value.split('-').pop()) / maxGrade) * 100;

	const firstColumnHeight = firstValue
		? minimized
			? firstValue / 4
			: firstValue
		: 5;
	const secondColumnHeight = secondValue
		? minimized
			? secondValue / 4
			: secondValue
		: 5;

	const gradeLines = Array.from(Array(4 * grades.length));

	return (
		<ChartWrapperStyled>
			{isMobile || (
				<ChartGradesStyled>
					<ChartGradesWrapperStyled>
						{grades.map((grade) => {
							const height = Math.floor(100 / grades.length);

							return (
								<ChartGradeItemStyled gradeHeight={height} key={grade}>
									<ChartGradeNumberStyled>{grade}</ChartGradeNumberStyled>
								</ChartGradeItemStyled>
							);
						})}
					</ChartGradesWrapperStyled>
					<ChartLinesWrapperStyled>
						{gradeLines.map((_, i) => {
							return <ChartGradeLineStyled key={i} />;
						})}
					</ChartLinesWrapperStyled>
				</ChartGradesStyled>
			)}

			<ChartBarWrapperStyled>
				<ChartBarStyled
					onClick={handleChartClick}
					barColor={colorPalette.black}
					gradeHeight={firstColumnHeight}
				>
					<ChartBarNumberStyled minimized={minimized}>
						{firstValue ? values.first.value : ''}
					</ChartBarNumberStyled>

					<ChartBarTitleStyled barColor={colorPalette.black}>
						{values.first.title}
					</ChartBarTitleStyled>
					<LabelMobileStyled barColor={colorPalette.black}>
						Klimaat nu
					</LabelMobileStyled>
				</ChartBarStyled>
				<VisibilitySensor onChange={onVisibilityChange}>
					<ChartBarStyled
						onClick={handleChartClick}
						barColor={colorPalette.red}
						gradeHeight={secondColumnHeight}
					>
						<ChartBarNumberStyled minimized={minimized}>
							{secondValue ? values.second.value : ''}
						</ChartBarNumberStyled>

						<ChartBarTitleStyled barColor={colorPalette.red}>
							{values.second.title}
						</ChartBarTitleStyled>
						<LabelMobileStyled barColor={colorPalette.red}>
							Klimaat in 2050
						</LabelMobileStyled>
					</ChartBarStyled>
				</VisibilitySensor>
			</ChartBarWrapperStyled>
		</ChartWrapperStyled>
	);
};
