import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { Link } from 'gatsby';

const BottomStyled = styled.div`
	position: relative;
	margin-top: auto;
	text-align: center;
`;

const LinkStyled = styled.p`
	padding-bottom: 35px;
	color: ${colorPalette.white};
	font-size: 15px;
	letter-spacing: 0;
	line-height: 18px;

	a {
		cursor: pointer;
		color: ${colorPalette.white};
		text-decoration: underline;

		&:hover {
			color: ${colorPalette.white};
			text-decoration: none;
		}

		&:active,
		&:focus {
			text-decoration: none;
			color: ${colorPalette.white};
		}
	}
`;

export const LandingBottom: FC = () => {
	return (
		<BottomStyled>
			<LinkStyled>
				Liever geen postcode invoeren?{' '}
				<Link to="/klimaat?postcode=6826DM">Bekijk een voorbeeld.</Link>
			</LinkStyled>
		</BottomStyled>
	);
};
