import { IChartValues, IChartProps } from '@components/ui';

export function transChartformValues(values: string[]): IChartValues {
	return {
		first: {
			value: values[0],
			title: 'Huidig klimaat',
		},
		second: {
			value: values[1],
			title: '2050',
		},
	};
}

/**
 * @param maxNumber Max grade
 * @param step			Step between grades
 * @param values		Values for chart columns
 */
export function getChartProps(
	maxNumber: number,
	step: number,
	values: string[]
): IChartProps {
	const grades = [0];
	let gradeNumber = 0;

	while (gradeNumber < maxNumber) {
		gradeNumber += step;
		grades.unshift(gradeNumber);
	}

	return {
		grades,
		values: transChartformValues(values),
	};
}
