import React, { FC } from 'react';
import styled from 'styled-components';
import { ArrowIcon } from './ArrowIcon';
import { colorPalette, Variant } from '@shared/theme';

interface IScrollProps {
	variant?: Variant;
	to: string;
	className?: string;
}

interface IStyledProps {
	variant: Variant;
}

const WrapperStyled = styled.div`
	display: flex;
	position: relative;
	height: 65px;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;

	&:hover {
		p {
			opacity: 1;
		}

		button {
			top: 0;
		}
	}

	@media screen and (max-width: 992px) {
		width: 100%;
		height: 50px;
	}
`;

const IconStyled = styled.button`
	position: absolute;
	top: 10px;
	padding: 0;
	margin: 0;
	line-height: 0;
	background: transparent;
	transition: top 0.22s linear;
	cursor: pointer;

	svg {
		transition: opacity 0.33s linear;
		color: ${(props: IStyledProps) =>
			props.variant === 'dark' ? colorPalette.black : colorPalette.white};
		fill: ${(props: IStyledProps) =>
			props.variant === 'dark' ? colorPalette.black : colorPalette.white};
	}

	@media screen and (max-width: 992px) {
		top: 0;
	}
`;

const TextStyled = styled.p`
	opacity: 0;
	padding: 0;
	margin: 0;
	transition: opacity 0.22s linear;
	color: ${(props: IStyledProps) =>
		props.variant === 'dark' ? colorPalette.black : colorPalette.white};

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

export const Scroll: FC<IScrollProps> = ({
	variant = 'dark',
	to,
	className,
}) => {
	if (typeof window === 'undefined' || !window.document) return null;

	const scrollToEl = document.querySelector<HTMLElement>(to);

	function onScrollClick() {
		window.scrollTo({ behavior: 'smooth', top: scrollToEl?.offsetTop });
	}

	return (
		<WrapperStyled className={className}>
			<IconStyled variant={variant} onClick={onScrollClick}>
				<ArrowIcon />
			</IconStyled>
			<TextStyled variant={variant}>Scroll verder</TextStyled>
		</WrapperStyled>
	);
};
