import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Title, TitleDivider, Button } from '@components/ui';
import { colorPalette } from '@shared/theme';
import { ArrowIcon } from './ArrowIcon';

interface IInfoSectionMobileProps {
	title: string;
	subtitle: string;
	image: string;
	cardHeight?: string;
	hasButton?: boolean;
	id?: string;
	href?: string;
	cardTitle?: string;
	withPadding?: boolean;
	cardPaddings?: boolean;
	cardContent?: ReactNode;
}

interface IStyledProps {
	cardPaddings?: boolean;
	withPadding?: boolean;
	cardHeight?: string;
}

const WrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;

	${(props: IStyledProps) => (props.withPadding ? 'margin-top: 50px' : '')};
`;

const ImageWrapperStyled = styled.div`
	position: relative;
`;

const ImageStyled = styled.img`
	width: 100%;

	@media screen and (max-width: 480px) {
		object-fit: cover;
	}
`;

const TitleStyled = styled.div`
	padding: 0 16px 50px;
`;

const CardStyled = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: -120px auto 0;
	padding: 0;
	max-width: 90%;
	min-height: 300px;
	height: ${(props: IStyledProps) => props.cardHeight || '100%'};
	box-sizing: border-box;
	padding-top: 32px;
	background-color: ${colorPalette.concrete};

	@media screen and (max-width: 380px) {
		max-width: 343px;
	}
`;

const CardTitleStyled = styled.h3`
	font-weight: bold;
	line-height: 1.47;
	margin: 0;
	padding: 0 0 32px 0;
	font-size: 19px;
	color: ${colorPalette.dimGray};
`;

const ButtonStyled = styled.div`
	button {
		margin: 40px 0;
		width: 175px;
		transition: all 0.22s linear;

		svg {
			transition: all 0.22s linear;
		}

		&:hover {
			color: ${colorPalette.white};
			background-color: ${colorPalette.red};

			svg {
				fill: ${colorPalette.white};
			}
		}
	}
`;

const ArrowStyled = styled.div`
	margin: 0 0 0 5px;
	line-height: 0;
`;

export const InfoSectionMobile: FC<IInfoSectionMobileProps> = ({
	children,
	title,
	subtitle,
	image,
	cardTitle,
	withPadding = false,
	id,
	href,
	cardHeight,
	hasButton = true,
	cardContent,
}) => {
	return (
		<WrapperStyled withPadding={withPadding} id={id}>
			<TitleDivider title={title} alignment="center" />
			<TitleStyled>
				<Title>{subtitle}</Title>
			</TitleStyled>

			<ImageWrapperStyled>
				<ImageStyled src={image} />
				<CardStyled cardHeight={cardHeight}>
					{cardTitle ? (
						<CardTitleStyled dangerouslySetInnerHTML={{ __html: cardTitle }} />
					) : null}
					{children}
					{hasButton ? (
						<ButtonStyled>
							<Button
								href={href}
								variant="hollow"
								size="md"
								bgColor={colorPalette.red}
								color={colorPalette.gray}
							>
								Bekijk de kaart
								<ArrowStyled>
									<ArrowIcon />
								</ArrowStyled>
							</Button>
						</ButtonStyled>
					) : null}
					{cardContent}
				</CardStyled>
			</ImageWrapperStyled>
		</WrapperStyled>
	);
};
