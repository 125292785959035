import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { TitleDivider, AddressInput } from '@components/ui';
import backgroundImage from './jumbotron-background.svg';

interface IJumbotronProps {
	postcode: string;
}

const JumbotronStyled = styled.div`
	position: relative;
	display: flex;
	background-color: ${colorPalette.black};
	height: 560px;
	background-image: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.2),
			rgba(0, 0, 0, 0.2)
		),
		url(${backgroundImage});
	background-repeat: no-repeat;
	background-position: right bottom;

	@media screen and (max-width: 992px) {
		padding: 0 16px;
	}

	@media screen and (max-width: 480px) {
		height: 480px;
		background-position: left center;
	}
`;

const TitleWrapperStyled = styled.div`
	display: flex;
	padding-left: 40px;
	height: 100%;
	justify-content: center;
	flex-direction: column;

	@media screen and (max-width: 992px) {
		padding-left: 0;
	}
`;

const TitleStyled = styled.h1`
	padding-top: 32px;
	font-size: 80px;
	line-height: 1.15;
	font-weight: bolder;
	font-family: 'Avenir Next LT Pro';
	color: ${colorPalette.white};
	max-width: 950px;

	@media screen and (max-width: 992px) {
		font-size: 60px;
		max-width: 90%;
		padding-bottom: 50px;
	}

	@media screen and (max-width: 768px) {
		font-size: 48px;
	}

	@media screen and (max-width: 480px) {
		font-size: 36px;
		max-width: 100%;
	}

	@media screen and (max-width: 375px) {
		font-size: 34px;
	}
`;

const AccentTitleStyled = styled.span`
	font-weight: bold;
	color: ${colorPalette.red};
`;

const InputWrapperStyled = styled.div`
	display: none;

	@media screen and (max-width: 992px) {
		display: block;
	}
`;

export const Jumbotron: FC<IJumbotronProps> = ({ postcode }) => {
	return (
		<JumbotronStyled>
			<TitleWrapperStyled>
				<TitleDivider title="Klimaat en u" variant="light" />
				<TitleStyled>
					Hoe zit het met de gevolgen van{' '}
					<AccentTitleStyled>klimaatverandering</AccentTitleStyled> in 2050 in
					uw omgeving?
				</TitleStyled>

				<InputWrapperStyled>
					<AddressInput value={postcode} />
				</InputWrapperStyled>
			</TitleWrapperStyled>
		</JumbotronStyled>
	);
};
