import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { Scroll } from '@components/ui';

interface ISpoilerProps {
	action: string;
	altAction: string;
	text: string;
	withPadding?: boolean;
	scrollTo?: string;
	withScroll?: boolean;
}

interface IStyledProps {
	isHidden: boolean;
	withPadding?: boolean;
}

const SpoilerWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ButtonStyled = styled.button`
	font-weight: 500;
	font-size: 16px;
	line-height: 1.5;
	color: ${colorPalette.blue};
	cursor: pointer;
	outline: none;
	box-shadow: none;
	border: none;
	margin-bottom: 32px;
	background: transparent;

	&:focus,
	&:active,
	&:hover {
		cursor: pointer;
		outline: none;
		box-shadow: none;
	}
`;

const TextWrapper = styled.div`
	text-align: center;
	max-height: ${(props: IStyledProps) => (props.isHidden ? '0' : '400px')};
	transition: all 0.22s linear;
	transition-property: max-height, padding-bottom;
	overflow: hidden;

	@media screen and (max-width: 992px) {
		padding-left: ${(props: IStyledProps) =>
			props.withPadding ? '16px' : '0'};
		padding-right: ${(props: IStyledProps) =>
			props.withPadding ? '16px' : '0'};
		padding-bottom: ${(props: IStyledProps) =>
			props.withPadding && !props.isHidden ? '20px' : '0'};
	}
`;

const ScrollWrapperStyled = styled.div`
	padding-top: 20px;
`;

export const Spoiler: FC<ISpoilerProps> = ({
	text,
	action,
	altAction,
	withPadding = false,
	scrollTo,
	withScroll = false,
}) => {
	const [isHidden, setHidden] = useState<boolean>(true);

	return (
		<SpoilerWrapperStyled>
			<ButtonStyled onClick={() => setHidden(!isHidden)}>
				{isHidden ? action : altAction}
			</ButtonStyled>
			<TextWrapper isHidden={isHidden} withPadding={withPadding}>
				{text}

				<ScrollWrapperStyled>
					{withScroll && scrollTo ? <Scroll to={scrollTo} /> : null}
				</ScrollWrapperStyled>
			</TextWrapper>
		</SpoilerWrapperStyled>
	);
};
