import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Button, ArrowIcon, TitleDivider, Title, Scroll } from '@components/ui';
import { colorPalette } from '@shared/theme';

interface IStyledProps {
	isVisible: boolean;
}

const WrapperStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 1280px;
	flex-direction: column;
	margin: 0 auto;
	padding: 60px 0 10px;

	@media screen and (max-width: 992px) {
		margin: 0 auto;
		max-width: 343px;
	}
`;

const ContentStyled = styled.p`
	margin: 0;
	color: ${colorPalette.white};
	font-size: 15px;
	font-weight: 500;
	line-height: 1.2;
	overflow: hidden;
	height: 100%;
	transition: 0.17s ease-in-out;
	transition-property: max-height, padding;
	padding: ${(props: IStyledProps) => (props.isVisible ? '0 0 24px 0' : '0')};
	max-height: ${(props: IStyledProps) => (props.isVisible ? '350px' : '0')};
`;

const ContentWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-bottom: 1px solid ${colorPalette.silver};
`;

const ButtonStyled = styled.button`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 24px 0;
	justify-content: space-between;
	font-size: 19px;
	font-weight: 500;
	line-height: 1.16;
	color: ${colorPalette.transparentSilver};
	cursor: pointer;
	background: transparent;
	text-align: left;

	@media screen and (max-width: 992px) {
		text-align: left;
		font-size: 17px;
		line-height: 1.47;
		padding: 16px 0;
	}
`;

const IconStyled = styled.div`
	display: flex;
	margin-left: 16px;
	align-items: center;
	justify-content: center;
	fill: ${colorPalette.red};
`;

const FaqButtonStyled = styled.div`
	margin-top: 60px;
	max-width: 176px;
	margin-bottom: 10px;

	button {
		&:hover {
			background: transparent;
			border: 1px solid ${colorPalette.white};
		}
	}
`;

const ArrowStyled = styled.span`
	margin: 0 0 0 5px;
	line-height: 0;
	fill: ${colorPalette.white};
`;

const AccordionStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	width: 100%;

	@media screen and (max-width: 992px) {
		max-width: 343px;
	}
`;

const TitleStyled = styled.div`
	text-align: center;
	max-width: 800px;
	padding-bottom: 60px;

	@media screen and (max-width: 992px) {
		h2 {
			max-width: 343px;
			font-size: 40px;
			text-align: left;
			padding: 32px 0 0;
		}

		padding-bottom: 50px;
	}
`;

const TitleDividerStyled = styled.div`
	@media screen and (max-width: 992px) {
		width: 100%;

		div {
			padding: 0;
			margin: 0;
			align-items: flex-start !important;
			max-width: 343px;
			text-align: left;
		}
	}
`;

const ScrollStyled = styled.div`
	margin: 20px 0;
	width: 100px;
`;

const LinkStyled = styled.a`
	padding: 0;
	margin: 0;
	font-weight: 500;
	color: ${colorPalette.red};

	&:hover,
	&:active,
	&:focus {
		color: ${colorPalette.red};
	}
`;

const CrossIconStyled = styled.div`
	position: relative;
	height: 22px;
	width: 22px;
	cursor: pointer;

	&.active {
		&:before {
			transform: translatey(-50%) rotate(-90deg);
			opacity: 0;
		}
		&:after {
			transform: translatey(-50%) rotate(0);
		}
	}

	&:before,
	&:after {
		content: '';
		display: block;
		background-color: ${colorPalette.red};
		position: absolute;
		top: 50%;
		left: 0;
		transition: 0.22s linear;
		width: 100%;
		height: 1px;
	}

	&:before {
		transform: translatey(-50%);
	}

	&:after {
		transform: translatey(-50%) rotate(90deg);
	}
`;

export const Accordion: FC = () => {
	const [openedItem, setOpenedItem] = useState<number>(0);

	function onToggle(id: number) {
		setOpenedItem(openedItem === id ? 0 : id);
	}

	return (
		<WrapperStyled id="veelgesteldevragen">
			<TitleDividerStyled>
				<TitleDivider
					alignment="center"
					title="Veelgestelde vragen"
					variant="light"
				/>
			</TitleDividerStyled>

			<TitleStyled>
				<Title variant="light">Antwoorden op meest gestelde vragen</Title>
			</TitleStyled>

			<AccordionStyled>
				<ContentWrapperStyled>
					<ButtonStyled onClick={() => onToggle(1)}>
						Welke aannames over klimaatverandering worden gehanteerd?
						<IconStyled>
							<CrossIconStyled className={openedItem === 1 ? 'active' : ''} />
						</IconStyled>
					</ButtonStyled>

					<ContentStyled isVisible={openedItem === 1}>
						De website sluit aan op de KNMI'14 scenario's. Het KNMI heeft in
						2014 klimaatscenario’s opgesteld die de bandbreedte schetsen
						waarbinnen klimaatverandering in Nederland tot 2050 en 2085
						waarschijnlijk zal plaatsvinden. Om de hoeveelheid informatie
						overzichtelijk te houden, is ervoor gekozen twee beelden met elkaar
						te vergelijken: huidig klimaat en het jaar 2050 onder scenario WH.
						Dit is voor de meeste klimaateffecten het worst-case scenario en
						geeft daarmee de bovenkant van de bandbreedte van verwachtingen
						weer. Meer informatie over de KNMI’14 scenario’s is{' '}
						<LinkStyled
							href="http://www.klimaatscenarios.nl/brochures/images/Brochure_KNMI14_NL_2015.pdf"
							target="_blank"
						>
							hier
						</LinkStyled>{' '}
						beschikbaar.
					</ContentStyled>
				</ContentWrapperStyled>
				<ContentWrapperStyled>
					<ButtonStyled onClick={() => onToggle(2)}>
						Hoe komt Esri aan deze data?
						<IconStyled>
							<CrossIconStyled className={openedItem === 2 ? 'active' : ''} />
						</IconStyled>
					</ButtonStyled>
					<ContentStyled isVisible={openedItem === 2}>
						Esri maakt gebruik van bestaande, betrouwbare Nederlandse
						databronnen. Dit kan data zijn die als basisregistratie door de
						overheid beschikbaar wordt gesteld, of data van verschillende
						toonaangevende data-partijen. Voor deze website is gebruik gemaakt
						van data verzameld door verschillende kennisinstellingen en
						beschikbaar gesteld door Climate Adaptation Services via{' '}
						<LinkStyled
							href="https://www.klimaateffectatlas.nl"
							target="_blank"
						>
							klimaateffectatlas.nl
						</LinkStyled>
					</ContentStyled>
				</ContentWrapperStyled>
				<ContentWrapperStyled>
					<ButtonStyled onClick={() => onToggle(3)}>
						Kan en mag ik deze data ook gebruiken? En zo ja, hoe kan dit?
						<IconStyled>
							<CrossIconStyled className={openedItem === 3 ? 'active' : ''} />
						</IconStyled>
					</ButtonStyled>
					<ContentStyled isVisible={openedItem === 3}>
						Deze data is openbaar beschikbaar gesteld via de klimaateffectatlas
						en mag gebruikt worden. Via ArcGIS kunt u deze data visualiseren en
						analyseren. Nog geen toegang tot ArcGIS? Vraag een kosteloze trial
						aan via{' '}
						<LinkStyled
							href="https://www.esri.nl/nl-nl/producten/trial"
							target="_blank"
						>
							esri.nl/nl-nl/producten/trial
						</LinkStyled>
					</ContentStyled>
				</ContentWrapperStyled>

				<FaqButtonStyled>
					<Button
						variant="filled"
						size="md"
						bgColor={colorPalette.red}
						color={colorPalette.white}
						onClick={() => navigate('faq')}
					>
						Bekijk alle FAQ
						<ArrowStyled>
							<ArrowIcon />
						</ArrowStyled>
					</Button>
				</FaqButtonStyled>

				<ScrollStyled>
					<Scroll to="#referenties" variant="light" />
				</ScrollStyled>
			</AccordionStyled>
		</WrapperStyled>
	);
};
