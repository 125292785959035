import { API_URLS } from '@api/urls';
import { IClimateApi, IGeometry, IGPSApi } from '@shared/interfaces';

class ClimateApi {
	public getData(postcode: string): Promise<IClimateApi> {
		const url = API_URLS.CLIMATE(postcode.replace(/ /g, ''));

		return new Promise((resolve, reject) => {
			fetch(url)
				.then((response) => response.json())
				.then(resolve)
				.catch(reject);
		});
	}

	public getByCoords(coords: IGeometry): Promise<IGPSApi> {
		const url = API_URLS.CLIMATE_BY_COORDS(coords);

		return new Promise((resolve, reject) => {
			fetch(url)
				.then((response) => response.json())
				.then(resolve)
				.catch(reject);
		});
	}
}

export const climateApi = new ClimateApi();
