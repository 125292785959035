import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';

type ButtonVariants = 'filled' | 'hollow';
type ButtonSizes = 'lg' | 'md';
type ButtonTypes = 'submit' | 'reset' | 'button';

interface IButtonProps {
	type?: ButtonTypes;
	color?: string;
	bgColor?: string;
	href?: string;
	onClick?(event: MouseEvent<HTMLElement>): void;
	size?: ButtonSizes;
	variant?: ButtonVariants;
	className?: string;
}
interface IStyledProps {
	size: string;
	color: string;
	bgColor: string;
	fontSize: string;
	variant: ButtonVariants;
}

const sizes = Object.freeze({ lg: '50px', md: '46px' });
const fontSizes = Object.freeze({ lg: '17px', md: '15px' });

const ButtonStyled = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	font-weight: 500;
	outline: none;
	box-shadow: none;
	cursor: pointer;
	font-size: ${(props: IStyledProps) => props.fontSize};
	height: ${(props: IStyledProps) => props.size};
	border: ${(props: IStyledProps) =>
		props.variant === 'hollow' ? `1px solid ${props.bgColor}` : 'none'};
	color: ${(props: IStyledProps) => props.color};
	background-color: ${(props: IStyledProps) =>
		props.variant === 'filled' ? props.bgColor : 'transparent'};

	&:active,
	&:focus,
	&:hover {
		border: ${(props: IStyledProps) =>
			props.variant === 'hollow' ? `1px solid ${props.bgColor}` : 'none'};
		outline: none;
		box-shadow: none;
	}
`;

const LinkStyled = styled.a`
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
`;

export const Button: FC<IButtonProps> = ({
	children,
	onClick,
	type = 'button',
	variant = 'filled',
	size = 'lg',
	href,
	color = colorPalette.white,
	bgColor = colorPalette.dimGray,
	className,
}) => {
	return (
		<>
			{href ? (
				<LinkStyled href={href} target="_blank">
					<ButtonStyled
						className={className}
						size={sizes[size]}
						fontSize={fontSizes[size]}
						variant={variant}
						color={color}
						bgColor={bgColor}
						onClick={onClick}
						type={type}
					>
						{children}
					</ButtonStyled>
				</LinkStyled>
			) : (
				<ButtonStyled
					className={className}
					size={sizes[size]}
					fontSize={fontSizes[size]}
					variant={variant}
					color={color}
					bgColor={bgColor}
					onClick={onClick}
					type={type}
				>
					{children}
				</ButtonStyled>
			)}
		</>
	);
};
