import React, { FC, useState, useEffect } from 'react';
import Swiper, { SwiperInstance } from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import styled from 'styled-components';
import { TitleDivider, Title, Button } from '@components/ui';
import { colorPalette } from '@shared/theme';
import slideImg1 from './slides/cbre-bepaalt-risico-klimaatschade-vastgoed.jpg';
import slideImg2 from './slides/effecten-klimaatverandering-nederland-climate-adaptation-services.jpg';
import slideImg3 from './slides/klimaatbestendig-stad-zwolle-senshagen-wijk.jpg';
import slideImg4 from './slides/droogte-klimaatverandering-satelliet-sentinel-2-esa.jpg';
import slideImg5 from './slides/bijdragen-beter-klimaat-waterschap-vallei-veluwe.jpg';
import slideImg6 from './slides/digitale-ontwikkeling-haven-rotterdam.jpg';
import slideImg7 from './slides/see-what-others-cant.png';

interface IStyledProps {
	isActive: boolean;
}

const WrapperStyled = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	margin: 0 auto;
	padding: 60px 0 100px;

	@media screen and (min-width: 1280px) {
		justify-content: space-between;
		max-width: 1280px;
	}

	@media screen and (max-width: 1200px) {
		padding: 60px 20px;
	}

	@media screen and (max-width: 992px) {
		padding: 60px 0 10px;
		flex-direction: column;
	}
`;

const CarouselWrapper = styled.div`
	max-width: 640px;
	margin-bottom: -6px;

	.swiper-container {
		padding-bottom: 25px;
	}

	@media screen and (max-width: 1200px) {
		max-width: 540px;
	}

	@media screen and (max-width: 992px) {
		order: 2;
		max-width: 480px;
	}

	@media screen and (max-width: 480px) {
		max-width: 320px;
	}
`;

const SlideStyled = styled.div`
	position: relative;

	@media screen and (min-width: 1280px) {
		min-width: 640px;
	}

	@media screen and (max-width: 992px) {
		max-width: 480px;
	}

	@media screen and (max-width: 480px) {
		max-width: 320px;
	}
`;

const SlideImageStyled = styled.img`
	width: 100%;
	object-position: 43% 50%;
	object-fit: cover;
	height: 1070px;

	@media screen and (max-width: 1135px) {
		height: 850px;
	}

	@media screen and (max-width: 992px) {
		height: 500px;
	}
`;

const TextStyled = styled.p`
	padding-top: 36px;
	font-size: 19px;
	font-weight: 500;
	line-height: 1.47;
	color: ${colorPalette.dimGray};
`;

const CarouselTitleStyled = styled.p`
	font-size: 19px;
	line-height: 1.47;
	color: ${colorPalette.dimGray};
	font-weight: bold;
	padding: 0;
	margin: 0;
	word-break: break-all;
`;

const SlideTitleStyled = styled.p`
	font-size: 19px;
	line-height: 1.47;
	color: ${colorPalette.dimGray};
	font-weight: bold;
	padding: 0;
	margin: 0;
	word-break: break-all;
`;

const CarouselTextStyled = styled.p`
	font-weight: 500;
	padding: 0;
	margin: 0;
	font-size: 16px;
	line-height: 1.5;
	color: ${colorPalette.lightGray};
`;

const SlideTextStyled = styled.p`
	font-weight: 500;
	padding: 0;
	font-size: 16px;
	line-height: 1.5;
	color: ${colorPalette.lightGray};
`;

const MobileSlideTextStyled = styled.p`
	font-weight: 500;
	padding: 32px;
	font-size: 19px;
	line-height: 1.47;
	color: ${colorPalette.white};
`;

const ContentStyled = styled.div`
	position: absolute;
	left: 0;
	bottom: 6px;
	max-width: calc(512px - 64px);
	padding: 32px;
	width: 100%;
	background-color: ${colorPalette.transparentSilver};

	@media screen and (max-width: 1200px) {
		max-width: 85%;
	}

	@media screen and (max-width: 992px) {
		max-width: 70%;
		padding: 32px;
	}
`;

const SlideSwiperStyled = styled.div`
	position: absolute;
	bottom: 40px;
	left: 32px;

	svg {
		transform: rotate(-180deg);
		margin-right: 5px;
		fill: ${colorPalette.white};
	}

	@media screen and (min-width: 992px) {
		display: none;
	}
`;

const InfoWrapper = styled.div`
	width: 100%;
	max-width: 522px;

	@media screen and (min-width: 1280px) {
		max-width: 1280px;
		margin-right: 60px;
	}

	@media screen and (min-width: 992px) {
		align-self: flex-end;
		margin-bottom: 25px;
	}

	@media screen and (max-width: 992px) {
		margin-left: 0;
		position: relative;
		top: auto;
		margin-bottom: 50px;
		text-align: center;

		p {
			display: none;
		}
	}
`;

const ControllersStyled = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 36px;
	border: 2px solid ${colorPalette.concrete};

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const ControllerStyled = styled.button`
	display: flex;
	flex-direction: column;
	padding: 16px 24px;
	align-items: flex-start;
	text-align: left;
	cursor: pointer;
	transition: background-color 0.22s linear;
	${(props: IStyledProps) =>
		props.isActive
			? `box-shadow: inset 4px 0 0 ${colorPalette.red} !important`
			: null};
	background-color: ${colorPalette.white};

	&:hover {
		background-color: ${colorPalette.concrete};
		${(props: IStyledProps) =>
			props.isActive
				? `box-shadow: inset 4px 0 0 ${colorPalette.red} !important`
				: null};

		&:not(:first-child) {
			border-top: 2px solid ${colorPalette.concrete};
		}
	}

	&:not(:first-child) {
		border-top: 2px solid ${colorPalette.concrete};
	}

	@media screen and (max-width: 1135px) {
		padding: 6px 14px;

		p {
			font-size: 15px;
		}
	}
`;

const CarouselLinkStyled = styled.a`
	display: block;
	margin-top: 20px;
	color: ${colorPalette.red};

	&:hover,
	&:focus,
	&:active {
		color: ${colorPalette.red};
	}
`;

const TitleDividerStyled = styled.div`
	@media screen and (max-width: 992px) {
		div {
			align-items: center !important;
		}
	}
`;

const DotsStyled = styled.div`
	display: flex;
	margin: 25px auto 50px;
	max-width: 280px;
	order: 3;
	justify-content: center;

	@media screen and (min-width: 992px) {
		display: none;
	}
`;

const DotStyled = styled.span`
	height: 6px;
	width: 40px;
	margin: 0 4px;
	background-color: ${(props: IStyledProps) =>
		props.isActive ? colorPalette.red : colorPalette.concrete};
	cursor: pointer;
`;

const InfoContainer = styled.div`
	margin-left: 60px;

	@media screen and (max-width: 1240px) {
		margin-left: 30px;
	}

	@media screen and (max-width: 992px) {
		margin-left: 0;
	}
`;

const MobileSlideStyled = styled.div`
	@media screen and (min-width: 992px) {
		display: none;
	}
`;

const MobileContentStyled = styled.div`
	position: absolute;
	left: 0;
	bottom: 7px;
	width: 100%;
	max-width: 100%;
	padding: 0;
	top: -7px;
	height: 100%;
	background-color: ${colorPalette.transparentBlack};
`;

const CreditsStyled = styled.a`
	position: absolute;
	bottom: -25px;
	left: 25px;
	font-size: 12px;
	font-weight: 500;
	opacity: 0.3;
	color: ${colorPalette.dimGray};
	cursor: pointer;

	&:hover {
		color: ${colorPalette.dimGray};
		text-decoration: underline;
	}

	@media screen and (max-width: 992px) {
		width: 100%;
		left: 0;
		text-align: center;
	}
`;

export const Carousel: FC = () => {
	const [swiperInstance, updateSwiper] = useState<SwiperInstance>(null);
	const [active, setActive] = useState<number>(0);

	useEffect(() => {
		if (swiperInstance !== null) {
			swiperInstance.on('slideChange', () => {
				setActive(swiperInstance.realIndex);
			});
		}
	}, [swiperInstance]);

	function goTo(id: number) {
		if (swiperInstance !== null) {
			swiperInstance.slideTo(id);
		}
	}

	function swipeNext() {
		if (swiperInstance !== null) {
			swiperInstance.slideTo(swiperInstance.activeIndex + 1);
		}
	}

	const SwiperButton = () => (
		<SlideSwiperStyled>
			<Button
				size="md"
				variant="hollow"
				bgColor={colorPalette.white}
				color={colorPalette.white}
				onClick={swipeNext}
			>
				Lees meer
			</Button>
		</SlideSwiperStyled>
	);

	const params = {
		loop: true,
		initialSlide: 0,
	};

	return (
		<WrapperStyled id="referenties">
			<CarouselWrapper>
				<Swiper getSwiper={updateSwiper} {...params}>
					<MobileSlideStyled>
						<SlideStyled>
							<SlideImageStyled src={slideImg7} />
							<MobileContentStyled>
								<MobileSlideTextStyled>
									Er is meer in onze wereld dan wat we met het blote oog kunnen
									zien. Esri’s Location Technology wordt door meer dan 350.000
									organisaties wereldwijd gebruikt om patronen en verbanden op
									de kaart te tonen.
								</MobileSlideTextStyled>
								{SwiperButton()}
							</MobileContentStyled>
						</SlideStyled>
					</MobileSlideStyled>
					<SlideStyled>
						<CreditsStyled
							href="https://commons.wikimedia.org/w/index.php?curid=15871495"
							target="_blank"
						>
							Door Coolcaesar at the English Wikipedia, CC BY-SA 3.0
						</CreditsStyled>
						<SlideImageStyled
							src={slideImg1}
							alt="Klimaatschade voor vastgoed: krijg net als CBRE inzicht in het risico op klimaatschade voor vastgoed"
						/>
						<ContentStyled>
							<SlideTitleStyled>CBRE</SlideTitleStyled>
							<SlideTextStyled>
								CBRE ontwikkelde het ‘Climate Resilience Dashboard’, een tool
								die inzicht biedt in de klimaatrisico’s van het
								vastgoedportfolio.
							</SlideTextStyled>
							<CarouselLinkStyled href="https://esri.nl/cbre">
								Lees het hele verhaal
							</CarouselLinkStyled>
						</ContentStyled>
					</SlideStyled>
					<SlideStyled>
						<SlideImageStyled
							src={slideImg2}
							alt="Gevolgen van klimaatverandering zijn door Climate Adaptation Services in kaart gebracht met de klimaateffectatlas, om samen te werken aan een klimaatbestendige omgeving."
						/>
						<ContentStyled>
							<SlideTitleStyled>Climate Adaptation Services</SlideTitleStyled>
							<SlideTextStyled>
								Climate Adaptation Services brengen met hun Klimaateffectatlas
								in kaart wat de gevolgen nu en mogelijk in de toekomst kunnen
								zijn. Dit is gemaakt met ArcGIS.
							</SlideTextStyled>
							<CarouselLinkStyled href="https://magazine.esri.nl/esri-magazine-1-2019/klimaateffectatlas/">
								Lees het hele verhaal
							</CarouselLinkStyled>
						</ContentStyled>
					</SlideStyled>
					<SlideStyled>
						<SlideImageStyled
							src={slideImg3}
							alt="Klimaatbestendige stad: in Zwolle werken inwoners en de gemeente samen om inzicht te krijgen in het lokale klimaat"
						/>
						<ContentStyled>
							<SlideTitleStyled>Gemeente Zwolle</SlideTitleStyled>
							<SlideTextStyled>
								SensHagen is een project dat als doel heeft om samen met de
								inwoners van de wijk Stadshagen het lokale klimaat inzichtelijk
								te maken via data en kaarten.
							</SlideTextStyled>
							<CarouselLinkStyled href="https://www.esri.com/videos/watch?videoid=Y1dG9tvQUW8&title=City%20of%20Zwolle%3A%20ArcGIS%20Hub%20Citizen%20Engagement ">
								Lees het hele verhaal
							</CarouselLinkStyled>
						</ContentStyled>
					</SlideStyled>
					<SlideStyled>
						<SlideImageStyled
							src={slideImg4}
							alt="Wat leert Sentinel-2-data van Ruimtevaartorganisatie ESA ons over droogte in Nederland?"
						/>
						<ContentStyled>
							<SlideTitleStyled>ESA – Sentinel-2-data</SlideTitleStyled>
							<SlideTextStyled>
								Ruimtevaartorganisatie ESA stelt via het Copernicus-programma
								Sentinel-2-data beschikbaar. De satellietbeelden kunnen via
								ArcGIS gebruikt worden voor analyses over bijvoorbeeld droogte.
							</SlideTextStyled>
							<CarouselLinkStyled href="https://magazine.esri.nl/esri-magazine-3-2018/droogtekaarten">
								Lees het hele verhaal
							</CarouselLinkStyled>
						</ContentStyled>
					</SlideStyled>
					<SlideStyled>
						<SlideImageStyled
							src={slideImg5}
							alt="Waterschap Vallei en Veluwe geeft inwoners inzicht hoe zij bij kunnen dragen aan een beter klimaat."
						/>
						<ContentStyled>
							<SlideTitleStyled>Waterschap Vallei en Veluwe</SlideTitleStyled>
							<SlideTextStyled>
								Het waterschap Vallei en Veluwe is continue bezig met het
								minimaliseren van de effecten van klimaatverandering. Dit doen
								zij door via data-analyses inzicht te geven aan burgers hoe zij
								zelf een bijdrage kunnen leveren.
							</SlideTextStyled>
							<CarouselLinkStyled href="https://www.youtube.com/watch?v=okp3GPv0dhU&list=PLKLQRNOv6pCOwm2ST9EcRrHOaP4lCVZIt&index=12">
								Lees het hele verhaal
							</CarouselLinkStyled>
						</ContentStyled>
					</SlideStyled>
					<SlideStyled>
						<SlideImageStyled
							src={slideImg6}
							alt="Haven van Rotterdam wil de slimste en beste ter wereld zijn door digitalisering."
						/>
						<ContentStyled>
							<SlideTitleStyled>Port of Rotterdam</SlideTitleStyled>
							<SlideTextStyled>
								De Port of Rotterdam werkt met Esri’s software voor
								routeplanning.
							</SlideTextStyled>
							<CarouselLinkStyled href="https://www.esri.com/en-us/see/stories/port-of-rotterdam/port-digitalization-article">
								Lees het hele verhaal
							</CarouselLinkStyled>
						</ContentStyled>
					</SlideStyled>
				</Swiper>
			</CarouselWrapper>
			<DotsStyled>
				<DotStyled isActive={active === 0} onClick={() => goTo(1)} />
				<DotStyled isActive={active === 1} onClick={() => goTo(2)} />
				<DotStyled isActive={active === 2} onClick={() => goTo(3)} />
				<DotStyled isActive={active === 3} onClick={() => goTo(4)} />
				<DotStyled isActive={active === 4} onClick={() => goTo(5)} />
				<DotStyled isActive={active === 5} onClick={() => goTo(6)} />
				<DotStyled isActive={active === 6} onClick={() => goTo(7)} />
			</DotsStyled>
			<InfoWrapper>
				<InfoContainer>
					<TitleDividerStyled>
						<TitleDivider title="Referenties" />
					</TitleDividerStyled>
					<Title>
						Gebruik maken <br /> van alle beschikbare data
					</Title>
					<TextStyled>
						Er is meer in onze wereld dan wat we met het blote oog kunnen zien.
						Esri’s Location Technology wordt door meer dan 350.000 organisaties
						wereldwijd gebruikt om patronen en verbanden op de kaart te tonen.
						Ontdek meer op{' '}
						{/* eslint-disable-next-line react/jsx-no-target-blank */}
						<a href="https://www.esri.com/see" target="_blank">
							esri.com/see
						</a>
						.
					</TextStyled>
					<ControllersStyled>
						<ControllerStyled isActive={active === 0} onClick={() => goTo(1)}>
							<CarouselTitleStyled>CBRE</CarouselTitleStyled>
							<CarouselTextStyled>
								Inzicht in klimaatrisico’s voor vastgoedportfolio
							</CarouselTextStyled>
						</ControllerStyled>
						<ControllerStyled isActive={active === 1} onClick={() => goTo(2)}>
							<CarouselTitleStyled>
								Climate Adaptation Services
							</CarouselTitleStyled>

							<CarouselTextStyled>
								Inzicht in effecten van klimaatverandering via
								klimaateffectatlas
							</CarouselTextStyled>
						</ControllerStyled>
						<ControllerStyled isActive={active === 2} onClick={() => goTo(3)}>
							<CarouselTitleStyled>Gemeente Zwolle</CarouselTitleStyled>
							<CarouselTextStyled>
								Inwoners betrekken bij klimaatverandering
							</CarouselTextStyled>
						</ControllerStyled>
						<ControllerStyled isActive={active === 3} onClick={() => goTo(4)}>
							<CarouselTitleStyled>ESA – Sentinel-2-data</CarouselTitleStyled>
							<CarouselTextStyled>
								Een nieuwe kijk op de droogte via Sentinel-2-data
							</CarouselTextStyled>
						</ControllerStyled>
						<ControllerStyled isActive={active === 4} onClick={() => goTo(5)}>
							<CarouselTitleStyled>
								Waterschap Vallei en Veluwe
							</CarouselTitleStyled>
							<CarouselTextStyled>Klimaatwerk in uitvoering</CarouselTextStyled>
						</ControllerStyled>
						<ControllerStyled isActive={active === 5} onClick={() => goTo(6)}>
							<CarouselTitleStyled>Port of Rotterdam</CarouselTitleStyled>
							<CarouselTextStyled>
								De beschikbare ruimte volledig benutten en altijd in controle
							</CarouselTextStyled>
						</ControllerStyled>
					</ControllersStyled>
				</InfoContainer>
			</InfoWrapper>
		</WrapperStyled>
	);
};
