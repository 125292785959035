import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { TitleDivider, Title } from '@components/ui';

const FormWrapperStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 1280px;
	margin: 0 auto;
	padding: 100px 90px;

	@media screen and (max-width: 1080px) {
		padding: 50px 16px;
	}
	@media screen and (max-width: 992px) {
		flex-direction: column;
		margin: 36px auto 0;
		max-width: 480px;
	}
`;

const FormStyled = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-left: 60px;
	max-width: 490px;

	/* NOTE: This is needed to override ESRI form styles */
	input.ao-form-field {
		height: 40px !important;
	}

	label.ao-form-label {
		margin: 0;
	}

	span.ao-form-error-message {
		padding-bottom: 1px;
	}

	.ao-combo-label {
		margin: 0 !important;
		text-align: left;

		span {
			line-height: 0;

			span {
				line-height: 1.3;
			}
		}
	}

	input[type='checkbox'] {
		margin: 4px 8px 0 0 !important;
	}

	.ao-combo-block > .ao-form-error + .ao-form-error-message {
		text-align: left;
		padding: 8px 0 25px !important;
	}

	input.ao-form-error {
		border: solid 1px #e4a793 !important;
		color: ${colorPalette.red};

		::placeholder,
		::-webkit-input-placeholder {
			color: ${colorPalette.red};
		}
	}

	span.ao-form-error-message {
		display: block;
		font-size: 14px;
		line-height: 1.14 !important;
		font-weight: 500;
		color: ${colorPalette.red};
		padding: 8px 0 !important;
	}

	.ao-richtext-block {
		padding-bottom: 25px;
	}

	.ao-submit-block {
		display: flex;
		justify-content: flex-start;
	}

	.ao-form-field {
		text-indent: 10px;
		font-size: 16px;
		font-weight: 500;
	}

	textarea.ao-form-field {
		resize: vertical;
		min-height: 120px;
	}

	.ao-form-submit {
		height: 50px !important;
		font-size: 17px !important;
		line-height: 1.53 !important;
		font-weight: 500 !important;
		cursor: pointer;
		background-color: ${colorPalette.dimGray} !important;
		transition: background-color 0.22s linear;

		&:hover {
			background-color: ${colorPalette.black} !important;
		}
	}

	.ao-richtext-block {
		p {
			margin: 0;
		}
	}

	@media screen and (max-width: 992px) {
		width: 100%;
		padding-left: 0;

		.ao-column-inner,
		.ao-row {
			padding: 0 !important;
		}

		[class*='column-'] [class*='column-']:last-of-type {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}
`;

const TitleWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 550px;

	@media screen and (min-width: 992px) {
		top: 100px;
		left: 0;
		position: sticky;
	}

	@media screen and (max-width: 1080px) {
		max-width: 450px;
	}
`;

const TitleStyled = styled.div`
	padding-bottom: 60px;

	@media screen and (max-width: 1080px) {
		font-size: 40px !important;
	}

	@media screen and (max-width: 992px) {
		padding: 32px 0 50px;

		h2 {
			padding: 0 !important;
		}
	}
`;

const LabelStyled = styled.p`
	font-weight: bold;
	padding: 0;
	margin: 0;
	font-size: 19px;
	line-height: 1.47;
	color: ${colorPalette.dimGray};
`;

const LinkStyled = styled.a`
	display: block;
	font-size: 19px;
	padding: 0;
	line-height: 1.18;
	color: ${colorPalette.lightGray};
	margin: 0 0 30px 0;

	&:hover {
		color: ${colorPalette.gray};
	}
`;

export const EsriForm: FC = () => {
	return (
		<FormWrapperStyled>
			<TitleWrapperStyled>
				<TitleDivider title="Kom in contact" />
				<TitleStyled>
					<Title>
						Laat uw gegevens achter en laat u inspireren hoe Esri uw bedrijf kan
						helpen
					</Title>
				</TitleStyled>

				<LabelStyled>E-mail ons</LabelStyled>
				<LinkStyled href="mailto:contact@esri.nl">contact@esri.nl</LinkStyled>
				<LabelStyled>Bel ons</LabelStyled>
				<LinkStyled href="tel:+31102170700">+31 (0)10 217 07 00</LinkStyled>
			</TitleWrapperStyled>

			<FormStyled>
				<script
					type="text/javascript"
					id="aoform-script-e9f55349-034e-4627-b92a-ee934fd531aa:d-0001"
				/>
			</FormStyled>
		</FormWrapperStyled>
	);
};
