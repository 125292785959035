import React, { FC } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { ArrowIcon } from '@components/ui';
import { colorPalette } from '@shared/theme';

interface IFaqHeaderProps {
	postcode: string | null;
}

const WrapperStyled = styled.div`
	display: flex;
	justify-items: flex-start;
	align-items: center;
	height: 60px;
	padding: 0 40px;
	background-color: ${colorPalette.headerColor};

	@media screen and (max-width: 992px) {
		padding: 0 15px;
	}
`;

const IconStyled = styled.div`
	padding-right: 5px;
	line-height: 0;

	svg {
		transform: rotateY(-180deg);
		fill: ${colorPalette.white};
	}
`;

const LinkStyled = styled.div`
	a {
		display: flex;
		align-items: center;
		color: ${colorPalette.white};
		cursor: pointer;

		&:hover,
		&:active,
		&:focus {
			color: ${colorPalette.white};
		}
	}
`;

export const FaqHeader: FC<IFaqHeaderProps> = ({ postcode }) => {
	function handleClick() {
		if (typeof window === 'undefined' || !window.document) return null;
		const url = `klimaat?postcode=${
			postcode || localStorage.getItem('postcode')
		}`;

		navigate(url);
	}

	return (
		<WrapperStyled>
			<LinkStyled>
				<a onClick={handleClick}>
					<IconStyled>
						<ArrowIcon />
					</IconStyled>
					Terug naar Klimaat en U
				</a>
			</LinkStyled>
		</WrapperStyled>
	);
};
