import React, { FC } from 'react';

export const TwitterIcon: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="15"
			viewBox="0 0 18 15"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M16.158 3.739c.008.164.01.328.01.488C16.168 9.232 12.454 15 5.66 15A10.238 10.238 0 0 1 0 13.298c.287.036.582.05.88.05 1.732 0 3.324-.602 4.587-1.62-1.578-.031-2.964-1.086-3.45-2.626a3.624 3.624 0 0 0 1.667-.066C1.96 8.68.721 7.126.721 5.325v-.046a3.635 3.635 0 0 0 1.674.474C.768 4.643.267 2.426 1.252.696 3.128 3.06 5.895 4.5 8.864 4.654c-.384-1.67.376-3.396 1.852-4.206a3.63 3.63 0 0 1 4.442.753 7.308 7.308 0 0 0 2.344-.92 3.789 3.789 0 0 1-1.623 2.097A7.28 7.28 0 0 0 18 1.779a7.595 7.595 0 0 1-1.842 1.96z"
			/>
		</svg>
	);
};
