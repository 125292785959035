import React, { FC } from 'react';

export const ErrorIcon: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
		>
			<g fill="#FFF">
				<path
					d="M10 0C7.008 0 5 1.128 5 4.063v10.625L10 20l5-5.313V4.063C15 1.127 12.991 0 10 0zm0 9.001c-.73 0-1.429-.29-1.945-.806C7.54 7.68 7.25 6.98 7.251 6.25c-.022-.996.498-1.926 1.357-2.43.86-.505 1.924-.505 2.784 0 .86.504 1.379 1.434 1.357 2.43 0 .73-.289 1.43-.804 1.945-.516.516-1.216.806-1.945.806z"
					opacity=".01"
				/>
				<path d="M14.14 0H5.857L0 5.858v8.284L5.856 20h8.285L20 14.142V5.858L14.14 0zM11.43 17.143H8.57v-2.857h2.858v2.857zm0-5.714H8.57V4.286h2.858v7.143z" />
			</g>
		</svg>
	);
};
