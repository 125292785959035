import React, { FC } from 'react';
import styled from 'styled-components';
import backgroundImage from './klimaatverandering-nederland-postcode-2050.jpg';

const BackgroundStyled = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55)),
		url(${backgroundImage}) no-repeat center bottom;
	background-size: cover;
`;

export const LandingBackground: FC = () => {
	return <BackgroundStyled />;
};
