import React, { FC } from 'react';

export const LinkedInIcon: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="18"
			viewBox="0 0 19 18"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M13.714 5.14c2.558 0 4.476 1.714 4.476 5.398v7.398h-3.888v-6.934c0-1.614-.564-2.71-1.97-2.71-1.074 0-1.712.742-1.994 1.456-.104.254-.13.614-.13.972v7.214h-3.89v-8.52c0-1.562-.05-2.868-.102-3.992h3.378l.178 1.738h.078c.512-.816 1.766-2.02 3.864-2.02zm-9.724.284v12.512H.102V5.424H3.99zM2.072 0c1.254 0 2.022.844 2.046 1.942 0 1.076-.792 1.946-2.096 1.946C.794 3.888 0 3.018 0 1.942 0 .844.818 0 2.072 0z"
			/>
		</svg>
	);
};
