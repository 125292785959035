import React, { FC } from 'react';

export const MailIcon: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="16"
			viewBox="0 0 21 16"
		>
			<g fill="#FFF" fillRule="evenodd">
				<path d="M0 0h20.469v2.878L10.234 8.713 0 2.878zM0 4.72l5.212 2.97L0 13.154zM20.469 4.72v8.434L15.256 7.69l5.213-2.97z" />
				<path d="M6.646 8.508l3.588 2.046 3.59-2.046 6.645 6.966H0z" />
			</g>
		</svg>
	);
};
