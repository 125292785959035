import React, { FC, useState, FormEvent, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { postcodeRegexp } from '@shared/regexp';
import { colorPalette } from '@shared/theme';
import { Button } from '@components/ui';
import { climateApi } from '@api/geo';
import { LocationIcon } from './LocationIcon';
import { LocationSpinner } from './LocationSpinner';

interface IStyledProps {
	isLoading?: boolean;
	hasError?: string;
}

const InputWrapperStyled = styled.form`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
	width: 400px;
	height: 56px;
	background-color: ${colorPalette.white};

	@media screen and (max-width: 768px) {
		width: 350px;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
		margin: 40px 15px 0;
	}
`;

const InputStyled = styled.input`
	max-width: 300px;
	padding: 0;
	margin: 0;
	border: none;
	outline: none;
	box-shadow: none;
	text-indent: 20px;
	font-size: 16px;
	color: ${colorPalette.black};
	font-weight: 500;
	color: ${(props: IStyledProps) =>
		props.hasError ? colorPalette.red : colorPalette.dimGray};

	::placeholder,
	::-webkit-input-placeholder {
		color: ${colorPalette.inputPlaceholderColor};
	}

	&:active,
	&:focus,
	&:hover {
		border: none;
		outline: none;
		box-shadow: none;
	}
`;

const LocationButtonStyled = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	background-color: transparent;
	border: none;
	outline: none;
	box-shadow: none;
	&:active,
	&:focus,
	&:hover {
		border: none;
		outline: none;
		box-shadow: none;
	}

	&:hover g {
		fill: ${colorPalette.lightGray};
	}
`;

const SubmitButtonWrapperStyled = styled.div`
	font-weight: 500;
	margin-right: 3px;
`;

const TransparentButtonStyled = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 40px;
	height: 100%;
	font-size: 14px;
	transition: color 0.22s linear;
	background: transparent;
`;

const LocationSpinnerWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
`;

// const ErrorStyled = styled.p`
// 	position: absolute;
// 	top: 60px;
// 	left: 0;
// 	color: ${colorPalette.red};
// `;

export const PostCodeInput: FC = () => {
	const MAX_LENGTH = 6;
	const ERROR_MESSAGE = 'Postcode niet gevonden';
	const [postcode, setPostcode] = useState<string>('');
	const [postcodeValidity, setPostcodeValidity] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	useEffect(() => {
		setPostcodeValidity(postcodeRegexp.test(postcode));
	}, [postcode]);

	function onpostcodeInput(event: FormEvent<HTMLInputElement>) {
		const value = event.currentTarget.value;

		if (value.length > MAX_LENGTH && postcodeRegexp.test(value)) {
			return setPostcode(value.toUpperCase());
		}

		if (value.length > MAX_LENGTH) return;

		setError('');
		setPostcode(value.toUpperCase());
	}

	function reset(event: FormEvent) {
		event.preventDefault();

		setPostcode('');
		setError('');
	}

	function getData(postcode: string) {
		if (loading) return;
		else setLoading(true);

		climateApi
			.getData(postcode)
			.then((response) => {
				if (!response.features.length) throw ERROR_MESSAGE;

				localStorage.setItem('postcode', postcode);
				navigate(`klimaat?postcode=${postcode}`);
			})
			.catch(setError)
			.finally(() => setLoading(false));
	}

	function onSubmit(event: FormEvent) {
		event.preventDefault();
		getData(postcode);
	}

	function onFindLocation(event: FormEvent) {
		event.preventDefault();

		if (loading) return;
		else setLoading(true);

		const geo = navigator.geolocation;

		if (!geo) return;

		const watcher = geo.watchPosition(({ coords }: Position) => {
			climateApi
				.getByCoords({ x: coords.longitude, y: coords.latitude })
				.then((response) => {
					if (!response.features.length) throw ERROR_MESSAGE;

					return response.features[0].attributes.PC6;
				})
				.then((postcode: string) => {
					getData(postcode);
				})
				.catch(setError)
				.finally(() => setLoading(false));

			geo.clearWatch(watcher);
		});
	}

	return (
		<InputWrapperStyled onSubmit={onSubmit}>
			<InputStyled
				onChange={onpostcodeInput}
				hasError={error}
				placeholder="Postcode"
				value={error || postcode}
			/>

			{error ? (
				<TransparentButtonStyled type="button" onClick={reset}>
					&#x2715;
				</TransparentButtonStyled>
			) : loading ? (
				<LocationSpinnerWrapper>
					<LocationSpinner />
				</LocationSpinnerWrapper>
			) : postcodeValidity ? (
				<SubmitButtonWrapperStyled>
					<Button type="submit">Bekijken</Button>
				</SubmitButtonWrapperStyled>
			) : (
				<LocationButtonStyled
					type="button"
					onClick={onFindLocation}
					title="Zoek mijn locatie"
				>
					<LocationIcon />
				</LocationButtonStyled>
			)}
		</InputWrapperStyled>
	);
};
