import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { IChartValues, Title } from '@components/ui';

interface IStyledProps {
	textColor?: string;
	bgColor?: string;
}

const RangeChartStyled = styled.div`
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 1135px) {
		height: 100%;
	}
`;

const ColumnStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${(props: IStyledProps) => props.textColor};

	&:first-child {
		margin-right: 90px;
	}

	@media screen and (max-width: 1135px) {
		&:first-child {
			margin-right: 60px;
		}
	}

	@media screen and (max-width: 992px) {
		width: 154px;

		&:first-child {
			margin-right: 3px;
		}
	}
`;

const TitleStyled = styled.div`
	h2 {
		padding: 0;
		margin: 0;
		color: ${(props: IStyledProps) => props.bgColor};
	}

	@media screen and (max-width: 1200px) {
		h2 {
			padding: 30px 0 0 0;
			margin: 0;
		}
	}

	@media screen and (max-width: 1135px) {
		font-size: 40px;

		h2 {
			padding: 0;
		}
	}

	@media screen and (max-width: 992px) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 154px;
		color: ${colorPalette.white};
		background-color: ${(props: IStyledProps) => props.bgColor};

		h2 {
			padding: 0;
			font-size: 55px !important;
			color: ${colorPalette.white};
		}
	}
`;
const LabelStyled = styled.p`
	padding-top: 30px;
	color: inherit;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const LabelMobileStyled = styled.p`
	@media screen and (min-width: 992px) {
		display: none;
	}

	max-width: 75px;
	padding: 24px 0 0 0;
	font-size: 19px;
	font-weight: 500;
	line-height: 1.47;
	margin: 0;
`;

export const RangeChart: FC<IChartValues> = ({ first, second }) => {
	return (
		<RangeChartStyled>
			<ColumnStyled textColor={colorPalette.black}>
				<TitleStyled bgColor={colorPalette.black}>
					<Title>{first.value}</Title>
				</TitleStyled>
				<LabelStyled>{first.title}</LabelStyled>
				<LabelMobileStyled>Huidig klimaat</LabelMobileStyled>
			</ColumnStyled>
			<ColumnStyled textColor={colorPalette.red}>
				<TitleStyled bgColor={colorPalette.red}>
					<Title>{second.value}</Title>
				</TitleStyled>
				<LabelStyled>{second.title}</LabelStyled>
				<LabelMobileStyled>Klimaat in 2050</LabelMobileStyled>
			</ColumnStyled>
		</RangeChartStyled>
	);
};
