import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';

interface ILinkProps {
	href: string;
	text: string;
	isBlank?: boolean;
	linkColor?: string;
}

interface IStyledProps {
	linkColor?: string;
}

const LinkStyled = styled.a`
	font-size: 15px;
	line-height: 1.2;
	font-weight: 500;
	color: ${(props: IStyledProps) => props.linkColor};
	outline: none;
	cursor: pointer;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: ${colorPalette.black};
		outline: none;
		cursor: pointer;
	}
`;

export const Link: FC<ILinkProps> = ({
	linkColor = colorPalette.dimGray,
	text,
	href,
	isBlank = true,
}) => {
	return (
		<LinkStyled
			linkColor={linkColor}
			href={href}
			target={isBlank ? '_blank' : ''}
		>
			{text}
		</LinkStyled>
	);
};
