import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';

interface INavProps {
	onToggle(opened: boolean): void;
}

interface IStyledProps {
	opened: boolean;
}

const HeaderWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`;

const HeaderStyled = styled.header`
	padding: 0 40px;
	height: 60px;
	background-color: ${(props: IStyledProps) =>
		props.opened ? colorPalette.black : colorPalette.headerColor};

	@media screen and (max-width: 768px) {
		padding: 0;
	}
`;

const HeaderTitleStyled = styled.h3`
	font-size: 19px;
	margin: 0;
	font-weight: 500;
	color: ${colorPalette.white};

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const MobileHeaderTitleStyled = styled.h3`
	font-size: 16px;
	font-weight: 500;
	padding: 0 0 0 16px;
	line-height: 1.25;
	margin: 0;
	color: ${colorPalette.white};

	@media screen and (min-width: 768px) {
		display: none;
	}
`;

const NavStyled = styled.ul`
	display: flex;
	align-items: center;
	flex-direction: row;
	height: 100%;
	list-style-type: none;

	@media screen and (max-width: 768px) {
		position: absolute;
		flex-direction: column;
		top: 60px;
		height: 100vh;
		z-index: 1;
		left: 0;
		width: 100%;
		display: ${(props: IStyledProps) => (props.opened ? 'flex' : 'none')};
		background-color: ${colorPalette.headerColor};
	}
`;

const NavItemStyled = styled.li`
	height: 100%;
	margin: 0;

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 45px;
	}
`;

const NavLinkStyled = styled.span`
	display: flex;
	padding: 0;
	margin: 0;
	height: 100%;

	a {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 0 16px;
		cursor: pointer;
		margin: 0;
		color: ${colorPalette.white};
		font-size: 16px;
		box-sizing: border-box;
		text-decoration: none;
		border-bottom: 3px solid ${colorPalette.headerColor};
		transition: all 0.1s ease-in-out;

		&:hover {
			@media screen and (max-width: 768px) {
				border-bottom: 3px solid transparent;
				background-color: ${colorPalette.gray};
			}

			text-decoration: none;
			color: ${colorPalette.white};
			border-bottom: 3px solid ${colorPalette.red};
			background-color: ${colorPalette.black};
		}
	}
`;

const NavButtonStyled = styled.button`
	display: none;
	align-items: center;
	height: 100%;
	outline: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	box-shadow: none;

	&:hover,
	&:active,
	&:focus {
		outline: none;
		border: none;
		box-shadow: none;
		background-color: transparent;
	}

	@media screen and (max-width: 768px) {
		display: flex;
		margin-right: 16px;
	}
`;

const TriangleStyled = styled.div`
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 7px solid ${colorPalette.white};

	transform: rotate(
		${(props: IStyledProps) => (props.opened ? '180deg' : '0deg')}
	);
`;

export const Header: FC<INavProps> = ({ onToggle }) => {
	if (typeof window === 'undefined' || !window.document) return null;

	const urlParams = new URLSearchParams(location.search);
	const postcode =
		urlParams.get('postcode') || localStorage.getItem('postcode') || '';

	const [opened, setOpened] = useState<boolean>(false);

	function toggleNav() {
		setOpened(!opened);
		onToggle(!opened);
	}

	function closeNav() {
		setOpened(false);
		onToggle(false);
	}

	return (
		<HeaderStyled opened={opened}>
			<HeaderWrapper>
				<HeaderTitleStyled>{postcode}</HeaderTitleStyled>
				<MobileHeaderTitleStyled>
					See What Other’s Can’t
				</MobileHeaderTitleStyled>

				<NavButtonStyled onClick={toggleNav}>
					<TriangleStyled opened={opened} />
				</NavButtonStyled>

				<NavStyled opened={opened}>
					<NavItemStyled>
						<NavLinkStyled onClick={closeNav}>
							<a href="#referenties">Referenties</a>
						</NavLinkStyled>
					</NavItemStyled>
					<NavItemStyled>
						<NavLinkStyled>
							<Link to="/faq" state={{ postcode }}>
								FAQ
							</Link>
						</NavLinkStyled>
					</NavItemStyled>
				</NavStyled>
			</HeaderWrapper>
		</HeaderStyled>
	);
};
