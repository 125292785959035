import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { Variant, Alignment, colorPalette } from '@shared/theme';

interface ITitleDividerProps {
	title: string;
	variant?: Variant;
	alignment?: Alignment;
	className?: string;
}

interface ITitleDidiverStyled {
	variant?: Variant;
}

const TitleStyled = styled.h3`
	font-family: 'Avenir Next LT Pro';
	font-size: 22px;
	padding: 0 0 15px;
	font-weight: bold;
	color: ${(props: ITitleDidiverStyled) =>
		props.variant === 'dark' ? colorPalette.dimGray : colorPalette.white};
`;

const DividerStyled = styled.div`
	width: 50px;
	height: 3px;
	background-color: ${colorPalette.red};
`;

export const TitleDivider: FC<ITitleDividerProps> = ({
	title,
	variant = 'dark',
	alignment = 'flex-start',
	className,
}) => {
	return (
		<Flex flexDirection="column" alignItems={alignment} className={className}>
			<TitleStyled variant={variant}>{title}</TitleStyled>
			<DividerStyled />
		</Flex>
	);
};
