export const colorPalette = {
	red: '#FE3A3A',
	blue: '#007AC1',
	black: '#151515',
	white: '#FFFFFF',
	gray: '#4C4C4C',
	silver: '#CCCCCC',
	dimGray: '#323232',
	concrete: '#F3F3F3',
	lightGray: '#828282',
	borderColor: '#A9A9A9',
	inputPlaceholderColor: '#6E6E6E',
	transparentSilver: 'rgba(255, 255, 255, 0.75)',
	headerColor: '#3D3D3D',
	transparentBlack: 'rgba(0, 0, 0, 0.5)',
	socialBarBackground: 'rgba(50, 50, 50, 0.95)',
};
