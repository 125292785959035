import React, { FC } from 'react';
import styled from 'styled-components';

interface IArrowIconProps {
	className?: string;
}

const SvgStyled = styled.svg`
	width: 14px;
	height: 13px;
`;

export const ArrowIcon: FC<IArrowIconProps> = ({ className }) => {
	return (
		<SvgStyled
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="0 0 14 13"
		>
			<path
				fillRule="nonzero"
				d="M6.933 11.527l4.594-4.594H0v-.866h11.527L6.933 1.473V.26l6.24 6.24-6.24 6.24z"
			/>
		</SvgStyled>
	);
};
