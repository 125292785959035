import React, { FC } from 'react';

export const MarkerIcon: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="20"
			viewBox="0 0 10 20"
		>
			<path
				fill="#FFF"
				fillRule="nonzero"
				d="M5 0C2.008 0 0 1.128 0 4.063v10.625L5 20l5-5.313V4.063C10 1.127 7.991 0 5 0zm0 9.001A2.75 2.75 0 0 1 2.25 6.25a2.75 2.75 0 1 1 5.5 0A2.75 2.75 0 0 1 5 9.001z"
			/>
		</svg>
	);
};
