import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { ArrowIcon } from '@components/ui/Scroll/ArrowIcon';

interface IStyledProps {
	isVisible: boolean;
}

const ButtonStyled = styled.button`
	position: fixed;
	bottom: 50px;
	width: 50px;
	height: 50px;
	cursor: pointer;
	z-index: 99999;
	background-color: ${colorPalette.socialBarBackground};
	transition: background-color 0.22s linear;

	&:hover,
	&:active {
		background-color: ${colorPalette.black};
	}

	@media screen and (min-width: 992px) {
		display: ${(props: IStyledProps) => (props.isVisible ? 'block' : 'none')};
	}

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const IconStyled = styled.div`
	width: 50px;
	height: 50px;
	transform: rotateX(180deg);

	svg {
		color: ${colorPalette.white};
		fill: ${colorPalette.white};
	}
`;

export const ToTop: FC = () => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		let prev = window.scrollY;

		const handleScroll = () => {
			if (prev < window.scrollY && window.scrollY >= 300) {
				setIsVisible(true);
			} else if (window.scrollY <= 300) {
				setIsVisible(false);
			}

			prev = window.scrollY;
		};

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function handleClick() {
		window.scrollTo({ behavior: 'smooth', top: 0 });
	}

	return (
		<ButtonStyled onClick={handleClick} isVisible={isVisible}>
			<IconStyled>
				<ArrowIcon />
			</IconStyled>
		</ButtonStyled>
	);
};
