import React, { FC } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import { TitleDivider, Title, PostCodeInput } from '@components/ui';
import { colorPalette } from '@shared/theme';

const HeadingStyled = styled.div`
	position: relative;
	padding-top: 60px;
	text-align: center;
`;

const SubTitleStyled = styled.h3`
	font-size: 19px;
	padding: 40px 15px 0;
	font-weight: 500;
	color: ${colorPalette.transparentSilver};
`;

const TitleStyled = styled.div`
	h2 {
		@media screen and (max-width: 1240px) {
			font-size: 55px;
		}

		@media screen and (max-width: 768px) {
			font-size: 44px;
			margin: 0 auto;
			padding-left: 32px;
			padding-right: 32px;
			max-width: 600px;

			br {
				display: none;
			}
		}
	}
`;

export const LandingHeading: FC = () => {
	return (
		<HeadingStyled>
			<TitleDivider
				title="Het klimaat en u"
				alignment="center"
				variant="light"
			/>
			<TitleStyled>
				<Title variant="light">
					Ervaar het klimaat <br /> van 2050 in uw omgeving
				</Title>
			</TitleStyled>
			<SubTitleStyled>
				Voer uw postcode in en kom erachter wat klimaatverandering voor u
				betekent
			</SubTitleStyled>
			<Flex justifyContent="center">
				<PostCodeInput />
			</Flex>
		</HeadingStyled>
	);
};
