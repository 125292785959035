import React, { FC } from 'react';
import styled from 'styled-components';
import { TitleDivider, Title, Button, CardDirection } from '@components/ui';
import { colorPalette } from '@shared/theme';
import { ArrowIcon } from './ArrowIcon';

interface IInfoSectionProps {
	title: string;
	text: string;
	href?: string;
	direction?: CardDirection;
	onClick?(): void;
}

interface IStyledProps {
	direction: CardDirection;
}

const WrapperStyled = styled.div`
	display: flex;
	position: sticky;
	top: 100px;
	margin-top: 120px !important;
	${(props: IStyledProps) => {
		return props.direction === 'right' ? 'order: 2' : 'order: 1';
	}};
	align-self: flex-start;
	flex-direction: column;
	max-width: 515px;

	@media screen and (max-width: 1240px) {
		margin-top: 60px !important;
		max-width: 455px;
	}

	@media screen and (max-width: 1040px) {
		max-width: 400px;
	}
`;

const TextStyled = styled.p`
	font-size: 19px;
	font-weight: 500;
	margin: 0;
	padding: 32px 0 40px;
	max-width: 455px;
	color: ${colorPalette.dimGray};
`;

const LinkStyled = styled.a`
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}

	button {
		display: flex;
		align-items: center;
		max-width: 163px;

		transition: all 0.22s linear;

		svg {
			transition: all 0.22s linear;
		}

		&:hover {
			color: ${colorPalette.white};
			background-color: ${colorPalette.red};

			svg {
				fill: ${colorPalette.white};
			}
		}
	}
`;

const ArrowStyled = styled.div`
	margin: 0 0 0 5px;
	line-height: 0;
`;

export const InfoSection: FC<IInfoSectionProps> = ({
	title,
	children,
	direction = 'left',
	href,
	text,
}) => {
	return (
		<WrapperStyled direction={direction}>
			<TitleDivider title={title} />
			<Title>{children}</Title>
			<TextStyled>{text}</TextStyled>
			<LinkStyled href={href} target="_blank">
				<Button
					variant="hollow"
					size="md"
					bgColor={colorPalette.red}
					color={colorPalette.gray}
				>
					Bekijk de kaart
					<ArrowStyled>
						<ArrowIcon />
					</ArrowStyled>
				</Button>
			</LinkStyled>
		</WrapperStyled>
	);
};
