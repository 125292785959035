import React, { FC, useState, useEffect, FormEvent } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { MarkerIcon } from './MarkerIcon';
import { ErrorIcon } from './ErrorIcon';
import { postcodeRegexp } from '@shared/regexp';
import { climateApi } from '@api/geo';
import { navigate } from 'gatsby';
import { LocationIcon, LocationSpinner, Button } from '@components/ui';

interface IAddressInputProps {
	value: string;
}

interface IStyledProps {
	hasError?: string;
	isLoading?: boolean;
	isHovered?: boolean;
	isActive?: boolean;
}

const WrapperStyled = styled.form`
	position: relative;
	display: flex;
	align-items: center;
	height: 40px;
	width: ${(props: IStyledProps) =>
		props.hasError
			? '285px'
			: props.isHovered || props.isActive
			? '240px'
			: '140px'};
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
	border: 6px solid ${colorPalette.white};
	transition: width 0.22s linear;
	background-color: ${colorPalette.white};

	@media screen and (max-width: 992px) {
		height: 50px;
		width: 300px;
		border: none;
		box-shadow: none;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
	}
`;

const InputStyled = styled.input`
	margin: 0;
	padding: 0 0 0 12px;
	outline: none;
	border: none;
	box-shadow: none;
	background: transparent;
	color: ${(props: IStyledProps) =>
		props.hasError ? colorPalette.red : colorPalette.dimGray};

	&:active,
	&:focus,
	&:hover {
		border: none;
		outline: none;
		box-shadow: none;
	}

	@media screen and (max-width: 992px) {
	}
`;

const LabelStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	height: 100%;
	background-color: ${(props: IStyledProps) =>
		props.hasError ? colorPalette.red : colorPalette.blue};

	@media screen and (max-width: 992px) {
		min-width: 50px;
	}
`;

const ButtonStyled = styled.div`
	max-width: 100px;

	@media screen and (max-width: 992px) {
		max-width: 90px;
		margin-right: 11px;
		height: 46px;
	}
`;

const TransparentButtonStyled = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 40px;
	height: 100%;
	font-size: 14px;
	transition: color 0.22s linear;
	background: transparent;

	&:hover {
		color: ${colorPalette.borderColor};
	}

	@media screen and (max-width: 992px) {
		margin-right: 5px;
	}
`;

const LocationSpinnerStyled = styled.div`
	@media screen and (max-width: 992px) {
		margin-right: 5px;
	}
`;

export const AddressInput: FC<IAddressInputProps> = ({ value }) => {
	const MAX_LENGTH = 6;
	const ERROR_MESSAGE = 'Postcode niet gevonden';
	const [postcode, setPostcode] = useState<string>('');
	const [postcodeValidity, setPostcodeValidity] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [active, setActive] = useState<boolean>(false);
	const [hovered, setHovered] = useState<boolean>(false);
	const [submitVisibility, setSubmitVisibility] = useState<boolean>(false);
	const [submitClickable, setSubmitClickable] = useState<boolean>(false);

	useEffect(() => {
		setPostcode(value);
	}, [value]);

	useEffect(() => {
		const postcodeValid = postcodeRegexp.test(postcode);

		setSubmitVisibility(postcodeValid);
		setPostcodeValidity(postcodeValid);
	}, [postcode]);

	function reset(event: FormEvent) {
		event.preventDefault();

		setPostcode('');
		setError('');
	}

	function onChange(event: FormEvent<HTMLInputElement>) {
		const value = event.currentTarget.value;

		if (value.length > MAX_LENGTH && postcodeRegexp.test(value)) {
			return setPostcode(value.toUpperCase());
		}

		if (value.length > MAX_LENGTH) return;

		setActive(true);
		setError('');
		setPostcode(value.toUpperCase());
	}

	function getData(postcode: string) {
		if (loading) return;
		else setLoading(true);

		climateApi
			.getData(postcode)
			.then((response) => {
				if (!response.features.length) throw ERROR_MESSAGE;

				localStorage.setItem('postcode', postcode);
				navigate(`klimaat?postcode=${postcode}`);
			})
			.catch(setError)
			.finally(() => setLoading(false));
	}

	function onSubmit(event: FormEvent) {
		event.preventDefault();
		if (postcodeValidity) getData(postcode);
	}

	function onFindLocation(event: FormEvent) {
		event.preventDefault();

		if (loading) return;
		else setLoading(true);

		const geo = navigator.geolocation;

		if (!geo) return;

		const watcher = geo.watchPosition(({ coords }: Position) => {
			climateApi
				.getByCoords({ x: coords.longitude, y: coords.latitude })
				.then((response) => {
					if (!response.features.length) throw ERROR_MESSAGE;

					return response.features[0].attributes.PC6;
				})
				.then((postcode: string) => {
					getData(postcode);
				})
				.catch(setError)
				.finally(() => setLoading(false));

			geo.clearWatch(watcher);
		});
	}

	function onBlur() {
		setActive(false);
		setSubmitVisibility(false);
	}

	return (
		<WrapperStyled
			hasError={error}
			onSubmit={onSubmit}
			isActive={!postcode ? true : active}
			isHovered={hovered}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<LabelStyled hasError={error}>
				{error ? <ErrorIcon /> : <MarkerIcon />}
			</LabelStyled>
			<InputStyled
				hasError={error}
				onBlur={onBlur}
				onChange={onChange}
				value={error || postcode}
				placeholder="Voer uw postcode in"
			/>

			{submitClickable ? null : submitVisibility && active ? null : (postcode &&
					!error &&
					hovered) ||
			  (active && postcode) ? (
				<TransparentButtonStyled type="button" onClick={reset}>
					&#x2715;
				</TransparentButtonStyled>
			) : null}

			{error ? (
				<TransparentButtonStyled type="button" onClick={reset}>
					&#x2715;
				</TransparentButtonStyled>
			) : null}

			{loading ? (
				<LocationSpinnerStyled>
					<LocationSpinner />
				</LocationSpinnerStyled>
			) : null}

			{!loading && !postcode.length && !error && !submitVisibility ? (
				<TransparentButtonStyled
					onClick={onFindLocation}
					title="Zoek mijn locatie"
				>
					<LocationIcon />
				</TransparentButtonStyled>
			) : null}

			{error ? null : !loading &&
			  postcode &&
			  (submitClickable || (submitVisibility && active)) ? (
				<ButtonStyled
					onMouseOver={() => setSubmitClickable(true)}
					onMouseLeave={() => setSubmitClickable(false)}
				>
					<Button type="submit" size="md">
						Bekijken
					</Button>
				</ButtonStyled>
			) : null}
		</WrapperStyled>
	);
};
