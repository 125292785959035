import React, { FC } from 'react';
import Loader from 'react-spinners/MoonLoader';
interface ILoaderProps {
	isLoading?: boolean;
}

export const LocationSpinner: FC<ILoaderProps> = ({ isLoading }) => {
	return (
		<div className="sweet-loading">
			<Loader size={20} loading={isLoading} />
		</div>
	);
};
