import React, { FC } from 'react';
import styled from 'styled-components';
import { Variant, colorPalette } from '@shared/theme';

interface ITitleProps {
	variant?: Variant;
	className?: string;
}

const TitleStyled = styled.h2`
	padding: 32px 0 0;
	font-size: 55px;
	margin: 0;
	line-height: 1.15;
	font-weight: bold;
	font-family: 'Avenir Next LT Pro';
	color: ${(props: ITitleProps) =>
		props.variant === 'dark' ? colorPalette.dimGray : colorPalette.white};

	@media screen and (max-width: 1240px) {
		font-size: 50px;
	}

	@media screen and (max-width: 1135px) {
		font-size: 48px;
	}

	@media screen and (max-width: 480px) {
		padding: 32px 15px 0;
		font-size: 40px;
	}
`;

export const Title: FC<ITitleProps> = ({
	children,
	variant = 'dark',
	className,
}) => {
	return (
		<TitleStyled variant={variant} className={className}>
			{children}
		</TitleStyled>
	);
};
