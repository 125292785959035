import React, { FC } from 'react';

export const ArrowIcon: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="50px"
			width="50px"
			viewBox="0 0 100 100"
			x="0px"
			y="0px"
		>
			<path d="M50,66.44a1,1,0,0,1-.72-.3l-30-30.89a1,1,0,0,1,1.43-1.39l30,30.89a1,1,0,0,1-.72,1.7Z" />
			<path d="M50,66.44a1,1,0,0,1-.72-1.7l30-30.89a1,1,0,0,1,1.43,1.39l-30,30.89A1,1,0,0,1,50,66.44Z" />
		</svg>
	);
};
