import React, { FC } from 'react';
import styled from 'styled-components';

const LayoutStyled = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	width: 100%;
	min-height: 800px;
`;

export const LandingLayout: FC = ({ children }) => {
	return <LayoutStyled>{children}</LayoutStyled>;
};
