import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';

interface IFaqMenuProps {
	activeItem?: string;
	activated(isActive: boolean): void;
}

interface IStyledProps {
	id?: string;
	isActive?: boolean;
}

const WrapperStyled = styled.div`
	display: flex;
	position: sticky;
	flex-direction: column;
	justify-content: center;
	top: 25px;
	min-width: 300px;
	height: 100%;
	box-sizing: border-box;
	left: 0;
	border: 2px solid ${colorPalette.concrete} !important;

	@media screen and (max-width: 992px) {
		display: none !important;
	}
`;

const ItemStyled = styled.button`
	display: flex;
	flex-direction: column;
	padding: 16px 24px;
	align-items: flex-start;
	text-align: left;
	cursor: pointer;
	font-size: 19px;
	font-weight: bold;
	color: ${colorPalette.black};
	background-color: ${colorPalette.white};
	transition: background-color 0.22s linear;
	${(props: IStyledProps) =>
		props.isActive
			? `box-shadow: inset 4px 0 0 ${colorPalette.red} !important`
			: null};

	&:hover {
		background-color: ${colorPalette.concrete};
		${(props: IStyledProps) =>
			props.isActive
				? `box-shadow: inset 4px 0 0 ${colorPalette.red} !important`
				: null};

		&:not(:first-child) {
			border-top: 2px solid ${colorPalette.concrete};
		}
	}

	&:not(:first-child) {
		border-top: 2px solid ${colorPalette.concrete};
	}
`;

export const FaqMenu: FC<IFaqMenuProps> = ({ activeItem, activated }) => {
	const [active, setActive] = useState<string>('#faqEsri');

	useEffect(() => {
		if (activeItem) setActive(activeItem);
	}, [activeItem]);

	function onScrollClick(id: string) {
		if (typeof window === 'undefined' || !window.document) return null;
		const scrollToEl = document.querySelector<HTMLElement>(id);
		const offsetTop = scrollToEl?.offsetTop;

		activated(true);

		const onScroll = function () {
			if (window.pageYOffset === offsetTop) {
				window.removeEventListener('scroll', onScroll);
				activated(false);
			}
		};

		window.addEventListener('scroll', onScroll);
		setActive(id);

		window.scrollTo({ behavior: 'smooth', top: offsetTop });
	}

	return (
		<WrapperStyled>
			<ItemStyled
				id="faqEsriButton"
				isActive={active === '#faqEsri'}
				onClick={() => onScrollClick('#faqEsri')}
			>
				Esri
			</ItemStyled>
			<ItemStyled
				id="faqDataButton"
				isActive={active === '#faqData'}
				onClick={() => onScrollClick('#faqData')}
			>
				Data
			</ItemStyled>
			<ItemStyled
				id="faqKlimaatButton"
				isActive={active === '#faqKlimaat'}
				onClick={() => onScrollClick('#faqKlimaat')}
			>
				Klimaat
			</ItemStyled>
			<ItemStyled
				id="faqOverigButton"
				isActive={active === '#faqOverig'}
				onClick={() => onScrollClick('#faqOverig')}
			>
				Overig
			</ItemStyled>
		</WrapperStyled>
	);
};
