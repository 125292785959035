import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import waveSvg from './wave.svg';

interface IWaterLevelProps {
	level?: string;
	description: string;
}

interface IStyledProps {
	level: number;
}

const WaterLevelStyled = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	@media screen and (max-width: 992px) {
		height: 300px;
	}
`;

const TitleStyled = styled.h2`
	font-size: 80px;
	margin: 0;
	padding: 0;
	z-index: 10;
	font-weight: bolder;
	color: ${colorPalette.red};
`;

const DescriptionStyled = styled.p`
	font-size: 19px;
	font-weight: 500;
	max-width: 370px;
	line-height: 1.47;
	padding: 0 0 20px 0;
	margin: 0;
	z-index: 10;
	color: ${colorPalette.dimGray};

	@media screen and (max-width: 1135px) {
		font-size: 16px;
		padding: 0 25px 10px;
	}

	@media screen and (max-width: 992px) {
		font-weight: bold;
		font-size: 19px;
		max-width: 200px;
		padding: 20px 0;
	}
`;

const WaveStyled = styled.div`
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: ${(props: IStyledProps) =>
		`${props.level > 100 ? 90 : props.level >= 95 ? 83 : props.level}%`};
	opacity: 0.3;
	background-color: ${colorPalette.silver};

	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 14px;
		top: -14px;
		background-image: url(${waveSvg});
	}
`;

const SubtitleStyled = styled.p`
	font-size: 15px;
	margin: 0;
	padding: 0;
	line-height: 1.47;
	color: ${colorPalette.red};
	z-index: 10;
`;

const LineWrapperStyled = styled.div`
	position: absolute;
	left: 0;
	top: 0;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const LineStyled = styled.div`
	width: 7px;
	height: 50px;
	background-color: rgba(0, 121, 193, 0.5);

	&:nth-of-type(even) {
		opacity: 0.5;
	}
`;

export const WaterLevel: FC<IWaterLevelProps> = ({
	level = '0',
	description,
}) => {
	const [value, subtitle = 'meter'] = level.split(' ');
	const levelPercentage = (Number(value.replace(',', '.')) / 5) * 100;
	const isMaxReached = levelPercentage > 100;
	const waterLevel = `${isMaxReached ? '+' : ''}${isMaxReached ? 5 : value}`;
	const isValidValue = Number(waterLevel.replace(',', '.'));

	return (
		<WaterLevelStyled>
			<LineWrapperStyled>
				{Array.from(Array(6)).map((_, i) => (
					<LineStyled key={i} />
				))}
			</LineWrapperStyled>
			{isValidValue ? (
				<>
					<DescriptionStyled
						dangerouslySetInnerHTML={{ __html: description }}
					/>
					<WaveStyled level={levelPercentage} />
					<TitleStyled>{waterLevel}</TitleStyled>
					<SubtitleStyled>{subtitle}</SubtitleStyled>
				</>
			) : (
				<DescriptionStyled>{level}</DescriptionStyled>
			)}
		</WaterLevelStyled>
	);
};
