import React, { FC } from 'react';

export const FacebookIcon: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="18"
			viewBox="0 0 10 18"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M9.998 3.13H7.142c-.338 0-.714.445-.714 1.036V6.23H10L9.46 9.17H6.428V18h-3.37V9.17H0V6.23h3.058v-1.73C3.058 2.017 4.779 0 7.142 0h2.856v3.13z"
			/>
		</svg>
	);
};
